import React from 'react'
import numeral from 'numeral'
import classNames from 'classnames'
import { Link, RouteComponentProps, withRouter } from '@deal/router'
import { Button } from '@deal/components'
import { isUserInsider } from '#src/app/services/insider'
import { useIdentityContext } from '#src/app/containers/Identity'
import { OrderForShoppingCartFooterFragment } from './Footer.generated'
import styles from './styles.css'

interface FooterProps extends RouteComponentProps<any> {
  order: OrderForShoppingCartFooterFragment
}

const Footer: React.FC<React.PropsWithChildren<FooterProps>> = ({ order }) => {
  const {
    id,
    subtotal,
    totalShippingCost,
    totalCostWithoutTaxAndTip,
    purchasableItemsCount,
    appliedPromotionEntry,
    lineItems,
    itemMinimumQuantitiesMet
  } = order

  /**
   * For insiders only, we surface the remaining balance to charge here since you could possibly be
   * applying your INSIDER (store credit) balance to your order. This surfaces an edge case where
   * you could possibly have applied a tip to your order, gone back to the site to shop around and
   * now your cart total doesn't add up. So, for Insiders and Insiders only, we show the tip as a line
   * item (if it's been applied). Otherwise the order is shown as a total without tip.
   */
  const { myself } = useIdentityContext()
  const appliedStoreCredit = numeral(order.appliedStoreCredit.amount)

  // trial items and regular sellables cannot be in the cart at the same time. this boolean is used
  // to show or hide the correct UI based on which type of cart/order it is
  const isOrderForTrial = lineItems.some(item => item.source.type === 'TRIAL_FEE')

  return (
    <div className={styles.module}>
      <div className={styles.row}>
        <div className={styles.label}>Subtotal</div>
        <div className={styles.value}>{numeral(subtotal.amount).format('$0,0.00')}</div>
      </div>
      {totalShippingCost && parseInt(totalShippingCost.amount, 10) > 0 && (
        <div className={styles.row}>
          <div className={styles.label}>Estimated shipping</div>
          <div className={styles.value}>{numeral(totalShippingCost.amount).format('$0,0.00')}</div>
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.label}>Estimated tax</div>
        <div className={styles.value}>Calculated at checkout</div>
      </div>
      {appliedPromotionEntry && numeral(appliedPromotionEntry.discount.amount).value()! > 0 && (
        <div className={styles.row}>
          <div className={styles.label}>{appliedPromotionEntry.promotion.code}</div>
          <div className={styles.free}>
            -{numeral(appliedPromotionEntry.discount.amount).format('$0,0.00')}
          </div>
        </div>
      )}
      {isUserInsider(myself?.user) && (
        <>
          {appliedStoreCredit.value()! > 0 && (
            <div className={styles.row}>
              <div className={styles.label}>Store credit</div>
              <div className={styles.free}>-{numeral(appliedStoreCredit).format('$0,0.00')}</div>
            </div>
          )}
        </>
      )}
      <div className={styles.row}>
        <div className={styles.label}>
          {isOrderForTrial ? 'Total held on card during trial' : 'Total'}
        </div>
        <div className={classNames(styles.value, styles.total)}>
          {numeral(totalCostWithoutTaxAndTip.amount).format('$0,0.00')}
        </div>
      </div>
      {appliedPromotionEntry && !!appliedPromotionEntry.rewardCredit && (
        <div className={styles.row}>
          <div className={styles.label}>Reward credit earned</div>
          <div className={styles.free}>
            {numeral(appliedPromotionEntry.rewardCredit.amount).format('$0,0.00')}
          </div>
        </div>
      )}
      <div className={styles.buttons}>
        <Link to={myself ? `/checkout/${id}` : '/checkout/guest'} className={styles.button}>
          <Button
            disabled={purchasableItemsCount === 0 || !itemMinimumQuantitiesMet}
            className={styles.button}
            data-testid="cart-secure-checkout-button"
          >
            Secure checkout
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default withRouter(Footer)
